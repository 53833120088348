<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container thin short">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>Confirm Submit</p>
                <span class="slash">/</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>

        <div class="modal-footer">
          <hr />
          <div class="buttons">
            <button
              class="loose"
              @click="$emit('submit')"
              :disabled="!submitValid"
            >
              ok
            </button>
            <button class="loose transparent" @click="$emit('close')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SubmitModal',
  props: ['submitValid'],
};
</script>

<style lang="scss" scoped>
button {
  width: 130px;
}
.head {
  height: 50px;
  display: flex;
  margin-bottom: 10px;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  .start {
    flex-grow: 1;
    text-align: left;
  }
}
</style>
